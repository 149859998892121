import React from 'react';
import styled from 'styled-components';

const StyledCaption = styled.span`
  color: var(--color-slate3);
  line-height: 1.75;
  max-width: 42.5rem;
  font-size: 0.875rem;
  margin-top: 1rem;

  @media screen and (min-width: 680px) {
    margin-top: 1.75rem;
  }
`;

const Caption: React.FC = ({ children }) => <StyledCaption>{children}</StyledCaption>;

export default Caption;
