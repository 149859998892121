import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '$utils/getImageData';
import { WrapXs } from '$components/Wraps';
import { BlockImageTextWidthProps } from './types';
import Caption from './Caption';
import Markdown from '$components/Markdown';

const Wrap = styled(WrapXs)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 1.5rem;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  @media screen and (min-width: 978px) {
    margin: 3.5rem auto;
  }
`;

const BlockImageTextWidth: React.FC<BlockImageTextWidthProps> = ({ image, caption }) => (
  <Wrap>
    <GatsbyImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
    {caption && (
      <Caption>
        <Markdown content={caption} />
      </Caption>
    )}
  </Wrap>
);

export default BlockImageTextWidth;
